<template>
  <div id="app">
    <!-- <carritoPopUp v-if="this.$route.name !== 'Dashboard' &&  this.$route.name !== 'login' && this.$route.name !== 'autentificacion' &&  this.$route.name !== 'detalles_de_pago_merch' && this.$route.name !== 'proximamente'"/> -->
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import carritoPopUp from './components/merch/carritoPopUp.vue';

export default {
  name: "App",
  data() {
    return {
      colores: "",
    };
  },
  components:{
    carritoPopUp
  },
  async created() {
    await this.getAllInfoClr("profile");
   
    this.colores = this.profiles;
    this.actualizarColores();

    let _ctk = localStorage.getItem("_ctk");
    if (!_ctk) {
      this.setCart({
        option: "set_cart",
        item: { id_cliente: null },
      });
    }
    await this.getCart()

  },
  watch: {
    profiles() {
      this.actualizarColores();
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions("colors", ["getAllInfoClr"]),
    ...mapActions("profile", ["getAllInfoPrf"]),
    ...mapActions("carrito", ["getCart","setCart"]),
    actualizarColores() {
      const arr = this.$store.getters["colors/data"];

      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {
          if (i == 0) {
            document.documentElement.style.setProperty(
              "--color-1",
              arr[i].code
            );
          } else if (i == 1) {
            document.documentElement.style.setProperty(
              "--color-2",
              arr[i].code
            );
          } else if (i == 2) {
            document.documentElement.style.setProperty(
              "--color-3",
              arr[i].code
            );
          } else if (i == 3) {
            document.documentElement.style.setProperty(
              "--color-4",
              arr[i].code
            );
          } else if (i == 4) {
            document.documentElement.style.setProperty(
              "--color-5",
              arr[i].code
            );
          } else if (i == 5) {
            document.documentElement.style.setProperty(
              "--color-6",
              arr[i].code
            );
          } else if (i == 6) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          } else if (i == 7) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          }
        }
      }
    },
  },
};
</script>

<style>
@import "./css/default.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@font-face {
  font-family: "Gramatika-Black";
  src: url("./assets/fonts/Gramatika-Black.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Gramatika-Bold";
  src: url("./assets/fonts/Gramatika-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Gramatika-Light";
  src: url("./assets/fonts/Gramatika-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Gramatika-Medium";
  src: url("./assets/fonts/Gramatika-Medium.ttf");
  font-display: swap;
}

input:disabled {
  background-color: #3c3c3c !important;
}

html {
  scroll-behavior: smooth;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #0055b8;
  --color-2: #ffc844;
  --color-3: #ffffff;
  --color-4: #f5f5f5;
  --color-5: #a7a7a7;
  --color-6: #d9d9d9;
  --color-7: #ffffff;
  --color-8: #92c0e9;
  --color-9: #3c3c3c;
  --color-10: #d4f0ff;
  --color-11: #edd8ff;
  --color-12: #fffad0;
  --color-13: #d9ffcc;
  --color-14: #f93549;
  --color-15: #b5ff9b;
  --color-16: #6cb6e0;
  --color-17: #fff496;


  --opacity: rgba(255, 255, 255, 0.6);


}
.body img{
  box-sizing: content-box !important;
  border-radius: unset !important;
}
#app {
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
}

button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

body {
  margin: 0vw;
}

.dflxc {
  display: flex;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  outline: none;
}

.dflx {
  display: flex;
}

.dflxa {
  display: flex;
  align-items: center;
}

.dflxca {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dflxsp {
  display: flex;
  justify-content: space-between;
}

.porel {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}

.box_ctr {
  display: flex;
  justify-content: center;
}

.box_ctr_alg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crs_ptr {
  cursor: pointer;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
  background-color: #F4F4F4;
  /** border-radius: 1.0416666666666665VW;  */
}

body::-webkit-scrollbar-button:increment,
body::-webkit-scrollbar-button {
  display: none;
}

body::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

body::-webkit-scrollbar-thumb {
  background-color: #ED87C0;
  /** border-radius: 1.0416666666666665VW;  */
  border: 0.10416666666666667vw solid #ED87C0;
}

body::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.cursorp {
  cursor: pointer;
}

p.err_msg {
  color: #A1D469 !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.carg_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .7vw;


}

.carg_btns p:first-child {
  margin-right: 1vw;
}

.brdr {
  height: 2px;
  background-color: #ccc;
  width: 70.7vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

p.subttl {
  color: var(--color-4);
  font-weight: 600;
  font-size: 1.3020833333333335vw;
  font-family: 'DM Sans';
  margin: 0vw;
  margin-bottom: 1vw;
}

.carg_btns p {
  background-color: #ED87C0;
  border-radius: 1.5vw;
  height: 2vw;
  margin: 0vw;
  letter-spacing: 3px;
  font-family: var(--DMSans);
  font-size: .7vw;
  width: 14vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carg_btns p img {
  width: 2.5vw;
}

.tbl_min {
  width: fit-content;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.mrtp {
  margin-top: 1vw !important;
}

.tbl_min_hdr {
  display: flex;
  align-items: center;
}

.tbl_min_hdr p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  color: #ffffff;
  font-family: 'DM Sans';
  background-color: var(--color-1);
  border-right: 1px solid white;
  margin: 0vw;
  padding: .3vw 0vw;
}

.tbl_min_row {
  display: flex;
  align-items: center;
  margin-top: .5vw;
}

.tbl_min_row p {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
  font-size: .8vw;
  font-family: 'DM Sans';
  margin: 0vw;
}

p.tblm1 {
  width: 15vw;
}

p.tblm2 {
  width: 6vw;
}

p.tblm3 {
  width: 10vw;
}

p.tblm4 {
  width: 6vw;
}

p.tblm5 {
  width: 6vw;
}

p.tblm6 {
  width: 8vw;
}

p.tblm7 {
  width: 8vw;
}

p.tblm7 img {
  cursor: pointer;
  width: .8vw;
}

p.bg-grey {
  background-color: #242424;
}


.loaderView {
  background-color: rgba(255, 255, 255, 0.082);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0vw;
  right: 0vw;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cargando {
  background-color: #ed87c0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5vw;
}

.cargando img {
  height: 50%;
}

.center-content{
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderSpinner {
  margin: auto !important;
  width: 50px !important;
  padding: 8px !important;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ed87c0;
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn)
  }
}

p.process {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--BLACK, #fff);
  font-family: Roboto;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1vw;
  text-transform: uppercase;
  letter-spacing: 2px;
}

@media (max-width: 834px) {
  .cargando {
    height: 3vw;
  }

  p.process {
    font-size: 2vw;
    margin-top: 2vw;
    padding-bottom: 10vw;
  }
}

@media (max-width: 530px) {
  .cargando {
    height: 5vw;
  }

  p.process {
    font-size: 4vw;
    margin-top: 5vw;
    padding-bottom: 10vw;
  }
}

</style>
